<template>
  <div class='view-home__part7 allClasses'>
    <div
      v-if="this.getCart.length"
      class="container-fluid size pr-0 style-top-container py-6"
    >
      <h1 class="my-3">CARRITO</h1>
      <div class="row pl-3 style-mobile-cart">
        <div
          class='col col-md-6-cart col-sm-12 col-lg container-instructors__body table-shopping-cart cart pr-3 pl-0'
        >
          <div class='details'>
            <h2>DETALLES</h2>
          </div>
          <div class='container-instructors'>
            <md-table>
              <md-table-row class='container-instructors__head'>
                <md-table-head class='hide-in-mobile'></md-table-head>
                <md-table-head>Producto</md-table-head>
                <md-table-head>Precio</md-table-head>
                <md-table-head></md-table-head>
              </md-table-row>
              <md-table-row v-for='course in getCart' :key='course.id'>
                <md-table-cell class='cell-img hide-in-mobile' width='100'>
                  <div class='d-flex align-items-center'>
                    <img :src='course.thumbnail_url' width='80' alt='profile' />
                  </div>
                </md-table-cell>
                <md-table-cell>
                  {{ course.name }}
                </md-table-cell>
                <md-table-cell>${{ course.price }} USD</md-table-cell>
                <md-table-cell width='50'>
                  <a style='cursor: pointer'
                     @click='removeCourse(course.id)'
                  ><i class='fas fa-trash-alt'></i
                  ></a>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
        <div
          class='col col-md-6-cart col-sm-12 table-shopping-cart container-buy pr-2'
        >
          <div class='buy'>
            <h2>RESUMEN</h2>
          </div>
          <div class='container-instructors'>
            <div class='row justify-content-between'>
              <div class='col-5'>
                <p>Total</p>
              </div>
              <div class='col-5 text-right'>
                <p class='total'>${{ this.getCartTotalPrice }} USD</p>
              </div>
            </div>
            <base-button
              class='btn btn-maquillate-effect-pay w-100 mt-3 mb-2 font-weight-500'
              :disabled='validateFields'
              @click='redirectToCheckout()'
            >Efectuar el pago
            </base-button>
            <button
              class='btn btn-continue w-100 font-weight-500 text-uppercase'
              @click='redirect()'
            >
              Continuar comprando
            </button>
          </div>
        </div>
        <div class='footer-buy'>
          <h3 class='font-weight-500'>Compra con confianza</h3>
          <ul>
            <li>
              <i class='far fa-calendar-alt'></i>
              <span style='color: black'
              >¡Compre sin riesgos! Ofrecemos una guarantía de 7 días en todos
                nuestros productos. Satisfacción guarantizada. Sus cursos pagos
                tendrán acceso completo de por vida, para que aprendas cuando
                quieras y desde donde quieras.
              </span>
            </li>
            <li>
              <i class='fas fa-life-ring'></i>
              <span style='color: black'
              >¡Tenemos un servicio al cliente en el que puedes contar! Vamos
                más allá para mantener feliz a nuestra comunidad.</span
              >
            </li>
            <li>
              <i class='fas fa-comment-dots'></i>
              <span style='color: black'
              >Al formar parte de nuestra plataforma, tendrás la oportunidad
                de ser parte de un grupo de personas con tus mismos intereses.
                También podrás ser parte de clases en vivo gratuitas que tendrán
                nuestros miembros con distintos invitados.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else class='container-fluid size pr-0 style-top-container'>
      <div class='text-center py-6'>
        <h1 class='details-shopping-cart'>Tu carrito</h1>
        <h3 class='details-shopping-cart_text'>Su carrito está vacío</h3>
        <button
          class='btn btn-maquillate rounded-0 px-4 py-2'
          @click='redirect()'
        >
          Continuar Comprando
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { DELETE_COURSE_TO_CART_AND_SAVE } from '@/store/actions/cart';
import { mapGetters } from 'vuex';

const OFFSET = 60;

export default {
  name: 'CartLayout',

  beforeRouteUpdate (next) {
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },

  props: {
    backgroundColor: {
      type: String,
      default: 'black',
    },
  },
  data () {
    return {
      bgcolor: 'background:#000;',
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
      showMenu: false,
      menuTransitionDuration: 250,
      pageClass: 'login-page',
      inputSearch: false,
      showCategories: false,
      bgColor: true,
      name: 'TableBasic',
    };
  },
  computed: {
    title () {
      return `${this.$route.name} Page`;
    },
    validateFields () {
      return this.getCart.length < 1;
    },
    ...mapGetters(['getCart', 'getCartCount', 'getCartTotalPrice']),
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground();
      },
    },
  },
  mounted () {
    this.lastScrollPosition = window.pageYOffset;
    window.addEventListener('scroll', this.onScroll);
    const viewportMeta = document.createElement('meta');
    viewportMeta.name = 'viewport';
    viewportMeta.content = 'width=device-width, initial-scale=1';
    document.head.appendChild(viewportMeta);
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll);
    this.removeBackgroundColor();
  },

  methods: {
    removeCourse (courseIdd) {
      this.$store
        .dispatch(DELETE_COURSE_TO_CART_AND_SAVE, courseIdd)
        .then(() => {
          //console.log(this.getCart)
          this.$notify({
            timeout: 1000,
            type: 'success',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Curso eliminado de carrito.',
          });
        })
        .catch(() => {
          this.$notify({
            timeout: 1000,
            type: 'danger',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Tenemos problemas al elimar el curso de su carrito',
          });
        });
      //this.guardar();
    },
    //buttons
    redirectToCheckout () {
      this.$router.push({ path: '/cart/checkout' });
    },
    redirect () {
      this.$router.push({ path: '/explorar' });
    },
    ///
    onScroll () {
      if (window.pageYOffset === 0) {
        this.bgColor = true;
        return;
      }
      if (window.pageYOffset < 0) {
        return;
      }
      if (window.pageYOffset > 400) {
        this.bgColor = false;
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        return;
      }

      this.showNavbar = window.pageYOffset < this.lastScrollPosition;
      this.lastScrollPosition = window.pageYOffset;
    },
    test () {
      this.$router.push();
    },
    search () {
      this.inputSearch = !this.inputSearch;
    },
    categories () {
      this.showCategories = !this.showCategories;
    },
    toggleNavbar () {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu () {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    },
    setBackgroundColor () {
      document.body.classList.add('bg-default');
    },
    removeBackgroundColor () {
      document.body.classList.remove('bg-default');
    },
    updateBackground () {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
  },
};
</script>
<style lang='scss'>
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}

.navbar-mobile {
  &.navbar-Header {
    display: none;
    margin-top: -2px;
    height: 68px;
    width: 100vw;
    background: transparent;
    position: fixed;
    transform: translate3d(0, 0, 0);
    transition: 0.3s all ease-out;
    z-index: 7;

    a {
      font-family: inherit !important;
    }

    @media (max-width: 1000px) {
      display: block !important;
    }

    .view-home-maquillate__header {
      margin-top: 13px;
    }
  }
}

.navbar-Header {
  margin-top: -2px;
  height: 68px;
  width: 100vw;
  position: fixed;
  transform: translate3d(0, 0, 0);
  transition: 0.3s all ease-out;
  z-index: 10;

  a {
    font-family: inherit !important;
  }

  @media (max-width: 1000px) {
    display: none;
  }

  .view-home-maquillate__header {
    margin-top: 13px;
  }
}

.bg-transparent-header {
  background: transparent;
}

.bg-black {
  background: #000;
}

.navbar-Header.hidden-navbar-Header {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.1s all ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.textCard h-auto {
  height: 14vh;
  overflow-y: auto;
}

.instructorCard {
  margin-top: 1rem;
}

.btn-maquillate-effect-pay {
  color: #000 !important;
  background-color: #ffcb33 !important;
  text-transform: inherit !important;
  border-color: #ffcb33 !important;
  font-weight: 500;
  text-transform: uppercase !important;
  font-size: 13px !important;
}

.btn-continue {
  font-size: 13px !important;
}

@media (max-width: 575px) {
  .hide-in-mobile {
    display: none;
  }
  .md-table-head-label {
    padding-left: 5px !important;
  }

  .md-table-cell-container {
    padding: 5px 10px 5px 5px !important;
  }

  .style-top-container {
    padding-top: 30px !important;
  }
}


.h-cart-layout {
  margin-bottom: -300px !important;
}
</style>
